import { useState } from "react";
import {
  Typography,
  Grid,
  Card,
  Stack,
  CardMedia,
  CardContent,
  IconButton,
  MenuItem,
  Menu,
  Container,
  Box,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { Link, useNavigate } from "react-router-dom";
import productInstance from "src/axios/productInstance";
import { toast } from "react-toastify";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

const ProductCard = ({
  product,
  isSavedSection,
  fetchProducts,
  fetchSavedProducts,
  savedProducts,
}) => {
  const navigate = useNavigate();
  const [showMenu, setShowMenu] = useState(false);

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    event.stopPropagation();
  };
  const handleClose = (event) => {
    event.stopPropagation();
    setAnchorEl(null);
  };

  const openInNewTab = (url) => {
    const newWindow = window.open(url, "_blank", "noopener,noreferrer");
    if (newWindow) newWindow.opener = null;
  };
  const productLink = isSavedSection
    ? `/dashboard/saved-products/${product._id}`
    : `/dashboard/products/${product._id}`;

  // const handleProductNavigation = () => {
  // navigate(
  //    isSavedSection
  //     ? `/dashboard/saved-products/${product._id}`
  //     : `/dashboard/products/${product._id}`,
  //   {
  //     state: {
  //       product: product,
  //       isSavedSection: isSavedSection,
  //     },
  //   }
  // );
  // };

  const handleProductNavigation = () => {
    // Store data in localStorage
    localStorage.setItem(
      "productData",
      JSON.stringify({
        product: product,
        isSavedSection: isSavedSection,
      })
    );
  };

  const renderMenuOptions = (product) => {
    return [
      {
        id: 1,
        name: product.priceInUSD,
        handler: () => {
          handleClose();
        },
      },
      {
        id: 2,
        name: (
          <a
            href={product.url}
            target="_blank"
            rel="noopener noreferrer"
            style={{ textDecoration: "none", color: "inherit" }}
          >
            Go
          </a>
        ),
        handler: () => {
          // openInNewTab(product.url);
          handleClose();
        },
      },
      {
        id: 4,
        name: isSavedSection
          ? "Remove"
          : savedProducts.filter((p) => p._id === product._id).length > 0
          ? "Remove from saved"
          : "Save",
        handler: (product) => {
          isSavedSection
            ? handleRemoveProduct(product)
            : savedProducts.filter((p) => p._id === product._id).length > 0
            ? handleRemoveProduct(product)
            : handleSaveProduct(product);

          fetchSavedProducts();
          handleClose();
        },
      },
      // {
      //   id: 5,
      //   name: "DEL",
      //   handler: () => {
      //     console.log("5");
      //     handleClose();
      //   },
      // },
      {
        id: 6,
        name: "VERO",
        handler: () => {
          const toastId = toast.loading("processing");

          toast.update(toastId, {
            render: product.vero,
            type: "info",
            isLoading: false,
            autoClose: 1000,
            hideProgressBar: true,
            closeOnClick: true,
            theme: "colored",
          });

          handleClose();
        },
      },
    ];
  };

  const showMenuIcon = () => {
    if (!showMenu) {
      setShowMenu(true);
    }
  };

  const hideMenuIcon = () => {
    if (showMenu) {
      setShowMenu(false);
    }
  };

  const handleSaveProduct = async (product) => {
    await productInstance.post(`/save/${product._id}`);

    const toastid = toast.loading("processing");

    toast.update(toastid, {
      render: "Product saved to your collection!",
      type: "success",
      isLoading: false,
      autoClose: 1000,

      position: "top-right",
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  };

  const handleRemoveProduct = async (product) => {
    await productInstance.post(`/remove/${product._id}`);

    const toastid = toast.loading("processing");

    toast.update(toastid, {
      render: "Product removed from your collection!",
      type: "success",
      isLoading: false,
      autoClose: 1000,
      position: "top-right",
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
    fetchProducts();
  };

  return (
    <Card
      raised
      onMouseEnter={showMenuIcon}
      onMouseLeave={hideMenuIcon}
      sx={{
        cursor: "pointer",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        padding: "1rem 0rem",
      }}
    >
      <Link
        to={productLink}
        style={{ textDecoration: "none", color: "inherit" }}
        onClick={() => handleProductNavigation()}
      >
        <LazyLoadImage
          src={product.imageUrl}
          alt={product.title}
          style={{
            borderRadius: "1rem",
            height: "15rem",
            width: "15rem",
          }}
          effect="blur"
        />
      </Link>
      {showMenu && (
        <Box
          sx={{
            position: "absolute",
            top: "1rem",
            right: "1rem",
          }}
        >
          <IconButton
            aria-label="more"
            id="long-button"
            aria-controls={open ? "long-menu" : undefined}
            aria-expanded={open ? "true" : undefined}
            aria-haspopup="true"
            onClick={handleClick}
            sx={{
              borderRadius: "10px",
              border: "1px solid grey",
              height: "2rem",
              width: "2rem",
              backgroundColor: "white",
              "&:hover": {
                backgroundColor: "white",
              },
            }}
          >
            <MoreVertIcon />
          </IconButton>
          <Menu
            id="options-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
          >
            {renderMenuOptions(product).map((option) => (
              <MenuItem
                key={option.id}
                onClick={(event) => {
                  handleClose(event);
                  option.handler(product);
                  event.stopPropagation();
                }}
              >
                {option.name}
              </MenuItem>
            ))}
          </Menu>
        </Box>
      )}

      <Link
        to={productLink}
        style={{ textDecoration: "none", color: "inherit" }}
        onClick={() => handleProductNavigation()}
      >
        <CardContent>
          <Typography gutterBottom variant="body2" component="div">
            {product.soldDate}
          </Typography>
          <Typography
            gutterBottom
            variant="h6"
            component="div"
            style={{
              display: "-webkit-box",
              WebkitLineClamp: "2",
              WebkitBoxOrient: "vertical",
              overflow: "hidden",
            }}
          >
            {product.title}
          </Typography>
          <Typography gutterBottom variant="body1" component="div">
            $ {product.priceInUSD}
          </Typography>
          <Typography gutterBottom variant="body2" component="div">
            {product.totalItemsSold} sold
          </Typography>
          <Typography gutterBottom variant="body2" component="div">
            {product.feedbackNumber
              ? `${product.feedbackNumber} feedbacks`
              : "No feedbacks available"}
          </Typography>
          <Typography gutterBottom variant="body2" component="div">
            {product.shipmentLocation}
          </Typography>
        </CardContent>
      </Link>
    </Card>
  );
};

export default ProductCard;
