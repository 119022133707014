// routes
import Router from "./routes/routes";
// theme
import ThemeProvider from "./theme";
// components
import ScrollToTop from "./components/ScrollToTop";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { PayPalScriptProvider } from "@paypal/react-paypal-js";

// ----------------------------------------------------------------------

export default function App() {
  return (
    <PayPalScriptProvider
      options={{
        currency: "USD",
        intent: "subscription",
        "client-id": process.env.REACT_APP_PAYPAL_CLIENT_ID,
      }}
    >
      <ThemeProvider>
        <ScrollToTop />
        <Router />
        <ToastContainer
          position="bottom-center"
          autoClose={50}
          hideProgressBar={true}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          draggable
          pauseOnHover={false}
        />
      </ThemeProvider>
    </PayPalScriptProvider>
  );
}
