import React, { useEffect } from "react";
import { Navigate, useRoutes, useNavigate } from "react-router-dom";
// layouts
import DashboardLayout from "../layouts/dashboard";
import LogoOnlyLayout from "../layouts/LogoOnlyLayout";
//

import Login from "../pages/Login";
import Register from "../pages/Register";

import NotFound from "../pages/Page404";
import DashboardApp from "../pages/DashboardApp";

import ForgotPassword from "src/pages/ForgotPassword";
import ResetPassword from "src/pages/ResetPassword";

//protected
import LoggedInProtection from "./LoggedInProtection";
import LoggedOutProtection from "./LoggedOutProtection";
import { getToken, deleteToken } from "src/store/localStorage";
import { loadProfile } from "src/store/actions/authActions";
import { useSelector, useDispatch } from "react-redux";
import VerifyEmail from "src/pages/VerifyEmail";
import EmailToBeVerified from "src/pages/EmailToBeVerified";
import Products from "src/pages/Products";
import SingleProduct from "src/pages/SingleProduct";
import SavedProducts from "src/pages/SavedProducts";
import Payment from "src/pages/Payment";
import Account from "src/pages/Account";
import Subscription from "src/pages/Subscription";
import CategoryProducts from "src/pages/CategoryProducts";
import StoreProducts from "src/pages/StoreProducts";

// ----------------------------------------------------------------------

export default function Router() {
  const { isSignedIn = false, isSubscribed } = useSelector(
    (state) => state.auth
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchProfile = async () => {
      const token = getToken();
      if (token && !isSignedIn) {
        const loadProfileResponse = await dispatch(loadProfile(token));
        if (loadProfileResponse) {
          if (loadProfileResponse.isSubscribed) {
            // navigate("/dashboard/main", { replace: true });
            const requestedUrl = window.location.pathname;

            if (requestedUrl) {
              navigate(requestedUrl, { replace: true });
            } else {
              console.log(
                "Requested URL not found, redirecting to /dashboard/main"
              );
              navigate("/dashboard/main", { replace: true });
            }
            return;
          }
          navigate("/dashboard/subscription");

          return;
        }
      }
      if (isSignedIn) {
        if (!isSubscribed) {
          navigate("/dashboard/subscription");
          return;
        }
      }
    };
    fetchProfile();
  }, [isSignedIn, dispatch, navigate, isSubscribed]);

  return useRoutes([
    {
      path: "/",
      element: <LogoOnlyLayout />,
      children: [
        { path: "/", element: <Navigate to="/login" /> },
        {
          path: "/signup",
          element: (
            <LoggedOutProtection redirectTo={"/dashboard/main"}>
              <Register />
            </LoggedOutProtection>
          ),
        },
        {
          path: "login",
          element: (
            <LoggedOutProtection redirectTo={"/dashboard/main"}>
              <Login />
            </LoggedOutProtection>
          ),
        },

        {
          path: "forgot-password",
          element: (
            <LoggedOutProtection redirectTo={"/dashboard/main"}>
              <ForgotPassword />
            </LoggedOutProtection>
          ),
        },
        {
          path: "reset-password",
          element: <ResetPassword />,
        },
        {
          path: "verify-email",
          element: <VerifyEmail />,
        },
        {
          path: "email-to-verify",
          element: <EmailToBeVerified />,
        },
        { path: "404", element: <NotFound /> },
        { path: "*", element: <Navigate to="/404" /> },
      ],
    },
    {
      path: "/payment",
      element: (
        <LoggedOutProtection redirectTo={"/"}>
          <Payment />
        </LoggedOutProtection>
      ),
    },
    {
      path: "/dashboard",
      element: (
        <LoggedInProtection redirectTo={"/"}>
          <DashboardLayout />
        </LoggedInProtection>
      ),
      children: [
        {
          path: "main",
          element: (
            <LoggedInProtection redirectTo={"/"}>
              <DashboardApp />
            </LoggedInProtection>
          ),
        },
        {
          path: "products",
          element: (
            <LoggedInProtection redirectTo={"/"}>
              <Products />
            </LoggedInProtection>
          ),
        },

        {
          path: "products/:productId",
          element: (
            <LoggedInProtection redirectTo={"/"}>
              <SingleProduct />
            </LoggedInProtection>
          ),
        },
        {
          path: "category/:categoryId",
          element: (
            <LoggedInProtection redirectTo={"/"}>
              <CategoryProducts />
            </LoggedInProtection>
          ),
        },
        {
          path: "store/:storeId",
          element: (
            <LoggedInProtection redirectTo={"/"}>
              <StoreProducts />
            </LoggedInProtection>
          ),
        },
        {
          path: "saved-products",
          element: (
            <LoggedInProtection redirectTo={"/"}>
              <SavedProducts />
            </LoggedInProtection>
          ),
        },
        {
          path: "saved-products/:productId",
          element: (
            <LoggedInProtection redirectTo={"/"}>
              <SingleProduct />
            </LoggedInProtection>
          ),
        },
        {
          path: "account",
          element: (
            <LoggedInProtection redirectTo={"/"}>
              <Account />
            </LoggedInProtection>
          ),
        },
        {
          path: "subscription",
          element: (
            <LoggedInProtection redirectTo={"/"}>
              <Subscription />
            </LoggedInProtection>
          ),
        },
      ],
    },

    { path: "*", element: <Navigate to="/404" replace /> },
  ]);
}
