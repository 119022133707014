import PayPalForm from "src/components/paypalForm";

import { Grid, Typography } from "@mui/material";

const Payment = () => {
  return (
    <Grid container spacing={3}>
      <Grid item md={6} sm={12} xs={12}>
        <Typography variant="h6" mt={4}>
          Payment
        </Typography>
        <PayPalForm />
      </Grid>
    </Grid>
  );
};

export default Payment;
