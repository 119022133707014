import {
  Card,
  CardContent,
  CardHeader,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import VideocamIcon from "@mui/icons-material/Videocam";
export default function GettingStartedCard() {
  return (
    <Card
      sx={{
        height: "100%",
        borderRadius: "1rem",
        boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.25)",
      }}
    >
      <CardContent>
        <Typography
          variant="h6"
          sx={{ color: "primary.main", marginBottom: "1rem" }}
        >
          Getting Started
        </Typography>
        <Stack spacing={2}>
          <Stack direction="row" alignItems="center" spacing={1}>
            <VideocamIcon color="primary" />
            <Typography variant="body1">First steps</Typography>
          </Stack>
          <Stack direction="row" alignItems="center" spacing={1}>
            <VideocamIcon color="primary" />
            <Typography variant="body1">Product research</Typography>
          </Stack>
        </Stack>
      </CardContent>
    </Card>
  );
}
