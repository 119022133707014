import { Grid, Typography } from "@mui/material";
import ProductCard from "src/components/ProductCard";

const ProductsCardsView = ({
  products,
  isSavedSection,
  fetchProducts,
  savedProducts,
  fetchSavedProducts,
}) => {
  return (
    <Grid container spacing={3}>
      {products?.length > 0 ? (
        products?.map((product) => {
          return (
            <Grid item md={3} sm={4} xs={6} key={product._id}>
              <ProductCard
                product={product}
                savedProducts={savedProducts}
                isSavedSection={isSavedSection}
                fetchProducts={fetchProducts}
                fetchSavedProducts={fetchSavedProducts}
              />
            </Grid>
          );
        })
      ) : (
        <Grid item md={12} sm={12} xs={12}>
          <Typography variant="h6" mt={4}>
            No products found
          </Typography>
        </Grid>
      )}
    </Grid>
  );
};

export default ProductsCardsView;
