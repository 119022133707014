import { Box, Typography } from "@mui/material";

const Footer = () => {
  return (
    <Box>
      <Box sx={{ backgroundColor: "#F9FBFB", position: "fixed", bottom: "0px", padding: "10px", width: "100%" }}>
        <Typography marginBottom={0} fontSize={"12px"}>
          &copy; Smart Dropper All Rights Reserved {new Date().getFullYear()}
        </Typography>
      </Box>

      <Box sx={{ position: "fixed", bottom: "10px", right: "20px" }}>
        <Typography marginBottom={0} fontSize={"12px"}>
          Built by:{" "}
          <a href="https://graysol.co" target="_blank">
            Graysol
          </a>
        </Typography>
      </Box>
    </Box>
  );
};

export default Footer;
