import { PayPalButton } from "react-paypal-button-v2";
import React from "react";

export function PayPalBtn(props) {
  const { createSubscription, onApprove, catchError, onError, onCancel } =
    props;

  return (
    <PayPalButton
      createSubscription={(data, details) => createSubscription(data, details)}
      onApprove={(data, details) => onApprove(data, details)}
      onError={(err) => onError(err)}
      catchError={(err) => catchError(err)}
      onCancel={(err) => onCancel(err)}
      options={{
        clientId: process.env.REACT_APP_PAYPAL_CLIENT_ID,
        vault: true,
      }}
      style={{
        shape: "pill",
        color: "blue",
        layout: "vertical",
        label: "subscribe",
      }}
    />
  );
}

export default PayPalBtn;
