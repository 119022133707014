import { useEffect, useReducer, useCallback } from "react";
import productInstance from "src/axios/productInstance";
import { Box, Grid, Pagination, Container } from "@mui/material";
import ProductsCardsView from "src/sections/@dashboard/products/productsCardView";

import _ from "lodash";

import CircularProgress from "@mui/material/CircularProgress";

const initialState = {
  loading: true,
  products: [],

  totalPages: 0,
  currentPage: 1,
  errorMessage: "",
};

const reducer = (state, action) => {
  switch (action.type) {
    case "start-loading":
      return { ...state, loading: true };
    case "stop-loading":
      return { ...state, loading: false };
    case "products":
      return { ...state, products: action.payload };

    case "totalPages":
      return { ...state, totalPages: action.payload };
    case "currentPage":
      return { ...state, currentPage: action.payload };
    case "errorMessage":
      return { ...state, errorMessage: action.payload };

    default:
      return state;
  }
};

const Products = () => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const paginate = (pageNumber) => {
    dispatch({ type: "currentPage", payload: pageNumber });
  };
  const fetchProducts = useCallback(async (queryString) => {
    dispatch({ type: "start-loading" });
    try {
      const response = await productInstance.get(`/saved`);
      const { products } = response.data;
      dispatch({ type: "products", payload: products });
      dispatch({ type: "errorMessage", payload: "" });
    } catch (error) {
      dispatch({
        type: "errorMessage",
        payload: error.response.data.message,
      });
    } finally {
      dispatch({ type: "stop-loading" });
    }
  }, []);

  useEffect(() => {
    fetchProducts();
  }, [fetchProducts]);

  return (
    <Box
      sx={{
        padding: "0rem 2rem",
      }}
    >
      <Grid container spacing={6}>
        {state.loading ? (
          <Container
            sx={{
              height: "50vh",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CircularProgress />
          </Container>
        ) : (
          <>
            <Grid item md={12} sm={12} xs={12}>
              <ProductsCardsView
                products={state.products}
                savedProducts={state.savedProducts}
                isSavedSection={true}
                fetchProducts={fetchProducts}
                fetchSavedProducts={fetchProducts}
              />
            </Grid>
            {state.products.length > 0 && (
              <Grid
                item
                md={12}
                sm={12}
                xs={12}
                mt={10}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Pagination
                  count={state.totalPages}
                  page={state.currentPage}
                  onChange={(event, value) => paginate(value)}
                  variant="outlined"
                  shape="rounded"
                  size="large"
                />
              </Grid>
            )}
          </>
        )}
      </Grid>
    </Box>
  );
};

export default Products;
