import {
  Box,
  Button,
  Grid,
  Stack,
  Typography,
  Breadcrumbs,
  Link,
  Paper,
  Card,
  Container,
} from "@mui/material";
import { useLocation, useParams, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import productInstance from "src/axios/productInstance";
import { CircularProgress } from "@mui/material";
import { toast } from "react-toastify";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

import Carousel from "react-grid-carousel";
import moment from "moment";

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 5,
    paritialVisibilityGutter: 60,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
    paritialVisibilityGutter: 50,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
    paritialVisibilityGutter: 30,
  },
};

const SingleProductView = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const { productId } = useParams();

  // In the components where you need to retrieve the data:
  const storedProductData =
    JSON.parse(localStorage.getItem("productData")) || {};
  // const product = storedProductData.product;
  const isSavedSection = storedProductData.isSavedSection;
  // const { isSavedSection } = location.state;
  const [product, setProduct] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isProductSaved, setIsProductSaved] = useState(false);
  const [similarProducts, setSimilarProducts] = useState([]);

  useEffect(() => {
    fetchProduct();
    fetchSimilarProducts();
  }, [productId]);

  const fetchProduct = async () => {
    const response = await productInstance.get(`/${productId}`);
    console.log("Product Run");
    setProduct(response.data.product);
    setIsProductSaved(response.data.isProductSaved);
    setLoading(false);
  };

  const fetchSimilarProducts = async () => {
    const response = await productInstance.get(`/similar/${productId}`);
    setSimilarProducts(response.data.similarProducts);
  };

  const openInNewTab = (url) => {
    const newWindow = window.open(url, "_blank", "noopener,noreferrer");
    if (newWindow) newWindow.opener = null;
  };

  const handleSaveProduct = async (product) => {
    await productInstance.post(`/save/${product._id}`);

    const toastid = toast.loading("processing");

    toast.update(toastid, {
      render: "Product saved to your collection!",
      type: "success",
      isLoading: false,
      autoClose: 1000,
      position: "top-right",
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  };

  const handleRemoveProduct = async (product) => {
    await productInstance.post(`/remove/${product._id}`);

    const toastid = toast.loading("processing");

    toast.update(toastid, {
      render: "Product removed from your collection!",
      type: "success",
      isLoading: false,
      autoClose: 1000,
      position: "top-right",
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  };

  const renderSimilarProducts = () => {
    return (
      <Carousel cols={5} rows={1} gap={20} loop pages={2}>
        {similarProducts.map((product) => (
          <Carousel.Item key={product._id}>
            <Box
              sx={{
                border: "1px solid grey",
                borderRadius: "1rem",
                padding: "1rem",
              }}
              onClick={() => {
                setLoading(true);
                navigate(`/dashboard/products/${product._id}`, {
                  state: {
                    isSavedSection: false,
                    product: product,
                  },
                });
              }}
              style={{
                cursor: "pointer",
              }}
            >
              <LazyLoadImage
                src={product.imageUrl}
                style={{
                  height: "12rem",
                  width: "100%",
                  borderRadius: "1rem",
                  marginBottom: "1rem",
                }}
                alt={product.title}
                effect="blur"
              />
              <Typography
                gutterBottom
                variant="body1"
                component="div"
                style={{
                  display: "-webkit-box",
                  WebkitLineClamp: "2",
                  WebkitBoxOrient: "vertical",
                  overflow: "hidden",
                }}
              >
                {product.title}
              </Typography>
              <Typography gutterBottom variant="body1" component="div">
                $ {product.priceInUSD}
              </Typography>
            </Box>
          </Carousel.Item>
        ))}
      </Carousel>
    );
  };

  const handleStoreNavigation = async (storeId) => {
    const store = storeId.split(" ").join("-");
    navigate(`/dashboard/store/${store}`);
  };

  const handleCategoryNavigation = async (categoryId) => {
    const category = categoryId.split(" ").join("-");

    navigate(`/dashboard/category/${category}`);
  };

  return (
    <>
      {loading ? (
        <Container
          sx={{
            height: "50vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress />
        </Container>
      ) : (
        <Grid container spacing={6} height="100%">
          <Grid item md={12} sm={12} xs={12}>
            <Breadcrumbs aria-label="breadcrumb">
              <Typography
                color="text.primary"
                onClick={() => {
                  isSavedSection
                    ? navigate("/dashboard/saved-products")
                    : navigate("/dashboard/products");
                }}
                style={{ cursor: "pointer" }}
              >
                {isSavedSection ? "Saved Products" : "Products"}
              </Typography>
              <Typography color="text.primary">{product.category}</Typography>
            </Breadcrumbs>
          </Grid>
          <Grid item md={12}>
            <Grid container spacing={6}>
              <Grid item md={3}>
                <img
                  src={product.imageUrl}
                  style={{
                    height: "18rem",
                    width: "18rem",
                    borderRadius: "1rem",
                    border: "1px solid grey",
                  }}
                  alt={product.title}
                />
              </Grid>

              <Grid item md={9}>
                <Stack spacing={2} justifyContent="center">
                  <Typography gutterBottom variant="h4" component="div">
                    {product.title}
                  </Typography>
                  <Typography
                    gutterBottom
                    variant="body1"
                    component="div"
                    color="primary"
                    onClick={() => {
                      handleCategoryNavigation(product.category);
                    }}
                    sx={{
                      cursor: "pointer",
                    }}
                  >
                    Category - {product.category}
                  </Typography>
                  <Typography
                    gutterBottom
                    variant="body1"
                    component="div"
                    color="primary"
                    onClick={() => {
                      handleStoreNavigation(product.store);
                    }}
                    sx={{
                      cursor: "pointer",
                    }}
                  >
                    Seller - {product.store}
                  </Typography>
                  <Typography gutterBottom variant="body1" component="div">
                    {product.totalItemsSold} sold
                  </Typography>
                  <Typography gutterBottom variant="body2" component="div">
                    {product.feedbackNumber
                      ? `${product.feedbackNumber} feedbacks`
                      : "No feedbacks available"}
                  </Typography>
                  <Typography gutterBottom variant="body1" component="div">
                    Price: {product.priceInUSD} USD
                  </Typography>
                  <Typography gutterBottom variant="body1" component="div">
                    Last sold on{" "}
                    {moment(product.soldDate, "DD/MM/YYYY").format(
                      "DD MMM YYYY"
                    )}
                  </Typography>

                  <Stack direction="row" spacing={3}>
                    <Button
                      variant="outlined"
                      size="medium"
                      sx={{ width: "10rem" }}
                      onClick={() => openInNewTab(product.url)}
                    >
                      Explore on Ebay
                    </Button>
                    <Button
                      variant="outlined"
                      size="medium"
                      sx={{ width: "10rem" }}
                      onClick={() => {
                        isProductSaved
                          ? handleRemoveProduct(product)
                          : handleSaveProduct(product);
                        setIsProductSaved(!isProductSaved);
                      }}
                    >
                      {isProductSaved ? "Remove" : "Save"}
                    </Button>
                  </Stack>
                </Stack>
              </Grid>
            </Grid>
          </Grid>
          <Grid item md={12} mt={3}>
            <Typography variant="h5" component="div">
              Similar Products
            </Typography>
          </Grid>
          <Grid item md={12}>
            {renderSimilarProducts()}
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default SingleProductView;
