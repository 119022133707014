import { Card, Box, Stack, Typography, Grid, CardContent } from "@mui/material";
import React, { useEffect, useState } from "react";

const GoogleTrends = ({
  trends,
  isMobile,
  isTablet,
  isDesktop,
  isLargeDesktop,
}) => {
  return (
    <Card
      sx={{
        height: "100%",
        borderRadius: "1rem",
        boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.25)",
      }}
    >
      <CardContent
        sx={{
          padding: isMobile ? "1rem" : isTablet ? "1.5rem" : "1rem",
        }}
      >
        <Typography
          variant="h6"
          sx={{ color: "primary.main", marginBottom: "1rem" }}
        >
          Trending google searches
        </Typography>
        <Grid
          container
          spacing={
            isMobile ? 3 : isTablet ? 2 : isDesktop ? 1 : isLargeDesktop ? 3 : 2
          }
          mt={2}
        >
          {trends.map((trend, index) => {
            return (
              <Grid
                item
                xs={12}
                md={12}
                lg={12}
                onClick={() => {
                  window.open(
                    `https://trends.google.com${trend.title.exploreLink}`,
                    "_blank"
                  );
                }}
                sx={{
                  cursor: "pointer",
                  "&:hover": {
                    backgroundColor: "background.default",
                  },
                }}
              >
                <Grid
                  container
                  spacing={2}
                  justifyContent="center"
                  alignItems="center"
                >
                  <Grid item xs={3} md={3} lg={3}>
                    <img
                      src={trend.image.imageUrl}
                      alt="trend"
                      style={{
                        width: isMobile
                          ? "3rem"
                          : isTablet
                          ? "4rem"
                          : isDesktop
                          ? "5rem"
                          : isLargeDesktop
                          ? "6rem"
                          : "7rem",
                        borderRadius: "0.5rem",
                      }}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={9}
                    md={9}
                    lg={9}
                    sx={{
                      height: "100%",
                    }}
                  >
                    <Stack
                      direction="row"
                      spacing={1}
                      justifyContent="space-between"
                    >
                      <Typography
                        variant="body1"
                        sx={{ color: "text.secondary" }}
                      >
                        {trend.title.query}
                      </Typography>
                      <Typography
                        variant="body1"
                        sx={{ color: "text.secondary" }}
                      >
                        {trend.formattedTraffic}
                      </Typography>
                    </Stack>
                  </Grid>
                </Grid>
              </Grid>
            );
          })}
        </Grid>
      </CardContent>
    </Card>
  );
};

export default GoogleTrends;
