import { useState } from "react";
import { Container, Box, Typography, Grid, Input, Button } from "@mui/material";
import couponInstance from "src/axios/couponInstance";
import subscriptionInstance from "src/axios/subscriptionInstance";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const CouponSection = () => {
  const navigate = useNavigate();

  const [code, setCode] = useState();
  const [coupon, setCoupon] = useState({});

  const fetchCoupon = async () => {
    const toastId = toast.loading("Loading...");
    try {
      const response = await couponInstance.get("/" + code);
      if (response.data.coupon) setCoupon(response.data.coupon);
      else {
        toast.update(toastId, {
          render: "Invalid Coupon code",
          type: "warn",
          isLoading: false,
          autoClose: 2000,
        });
      }
    } catch (error) {
      toast.update(toastId, {
        render: "You can't avail this coupon",
        type: "warn",
        isLoading: false,
        autoClose: 2000,
      });
      console.log(error);
    }
  };

  const redeemCoupon = async () => {
    const toastId = toast.loading("Loading...");
    try {
      await subscriptionInstance
        .post("/coupon-subscription", { code })
        .then((res) => {
          toast.update(toastId, {
            render: "Subscription created successfully",
            type: "success",
            isLoading: false,
            autoClose: 1000,
          });

          window.location.reload();
        })
        .catch((err) => {
          toast.update(toastId, {
            render: "Subscription creation failed",
            type: "warn",
            isLoading: false,
            autoClose: 2000,
          });
        });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Container maxWidth="lg">
      <Box>
        <Typography as="h1" sx={{ fontWeight: 800, fontSize: "30px" }}>
          Coupons
        </Typography>
        <Typography as="p">Redeem your coupon.</Typography>
      </Box>

      <Grid container spacing={5} sx={{ marginTop: 3 }}>
        <Grid item md="3" xs="12">
          <Typography as="label" sx={{ fontSize: 14, color: "#919EAB" }}>
            Enter your coupon code
          </Typography>
          <Input value={code} onChange={(e) => setCode(e.target.value)} type="text" sx={{ width: "100%" }} name="code" placeholder="FREE1" />
          <Box sx={{ marginTop: 2 }}>
            <Button onClick={fetchCoupon} variant="outlined" size="medium">
              Verify
            </Button>
          </Box>
        </Grid>
        {coupon.code && (
          <Grid item md="9" xs="12">
            <Grid container justifyContent="space-between">
              <Grid item>
                <Typography as="h6" sx={{ fontWeight: 800 }}>
                  Coupons
                </Typography>
                <Typography as="p">{coupon.time} Months Subscription</Typography>
              </Grid>
              <Grid item>
                <Typography textAlign="right" as="h6" sx={{ fontWeight: 800 }}>
                  Price
                </Typography>
                <Typography textAlign="right" as="p">
                  $0.00
                </Typography>
                <Button onClick={redeemCoupon} sx={{ marginTop: 1 }} variant="outlined" size="medium">
                  Redeem your coupon
                </Button>
              </Grid>
            </Grid>
          </Grid>
        )}
      </Grid>
    </Container>
  );
};

export default CouponSection;
