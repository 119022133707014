import { useState } from "react";
import PayPalSubscription from "src/sections/subscription";
import CouponSection from "src/sections/coupon";

export default function Subscription() {
  const [display, setDisplay] = useState(true);
  const toggleDisplay = () => setDisplay(!display);

  return display ? <PayPalSubscription toCoupon={toggleDisplay} /> : <CouponSection />;
}
