import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
} from "chart.js";
import { Bar } from "react-chartjs-2";

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip);

export const options = {
  responsive: true,
  plugins: {
    legend: {
      display: false,
    },

    title: {
      display: true,
      text: "Products vs Categories",
    },
  },
};

export default function BarChart({ products }) {
  return (
    <Bar
      options={options}
      data={{
        labels: products.map((product) => product._id.split(" ")[0]),
        datasets: [
          {
            data: products.map((product) => product.count),
            backgroundColor: "#8C30F5",
          },
        ],
      }}
    />
  );
}
