import { Box } from "@mui/material";
import SingleProductView from "src/sections/@dashboard/products/singleProductView";

const SingleProduct = () => {
  return (
    <Box
      sx={{
        padding: "0rem 2rem",
      }}
    >
      <SingleProductView />;
    </Box>
  );
};

export default SingleProduct;
