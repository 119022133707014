import {
  Card,
  CardContent,
  CardHeader,
  Grid,
  Stack,
  Typography,
} from "@mui/material";

import { WhatsApp, Facebook } from "@mui/icons-material";

export default function ConnectCard() {
  return (
    <Card
      sx={{
        height: "100%",
        borderRadius: "1rem",
        boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.25)",
      }}
    >
      <CardContent>
        <Typography
          variant="h6"
          sx={{ color: "primary.main", marginBottom: "1rem" }}
        >
          Information & Community
        </Typography>

        <Stack spacing={2}>
          <Stack direction="row" alignItems="center" spacing={1}>
            <WhatsApp color="primary" />
            <Typography variant="body1">
              International Whatsapp Group
            </Typography>
          </Stack>
          <Stack direction="row" alignItems="center" spacing={1}>
            <WhatsApp color="primary" />
            <Typography variant="body1">Hebrew Whatsapp Group</Typography>
          </Stack>
          <Stack direction="row" alignItems="center" spacing={1}>
            <Facebook color="primary" />
            <Typography variant="body1">Facebook Page</Typography>
          </Stack>
          <Stack direction="row" alignItems="center" spacing={1}>
            <Facebook color="primary" />
            <Typography variant="body1">Facebook Group</Typography>
          </Stack>
        </Stack>
      </CardContent>
    </Card>
  );
}
