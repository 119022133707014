import { useState } from "react";

import {
  Grid,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
} from "@mui/material";

import subscriptionInstance from "src/axios/subscriptionInstance";
import moment from "moment";
import CircularProgress from "@mui/material/CircularProgress";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { cancelSubscription } from "src/store/actions/authActions";

import useSWR, { mutate } from "swr";
import { toast } from "react-toastify";

const BillingSection = () => {
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const [cancelLoading, setCancelLoading] = useState(false);

  const fetchTransactions = async () => {
    const response = await subscriptionInstance.get(
      "/get-subscription-transactions"
    );
    return response.data.transactions;
  };

  const { data: transactions, isLoading } = useSWR(
    "/get-subscription-transactions",
    fetchTransactions
  );

  const handleCancelSubscription = async () => {
    setCancelLoading(true);
    try {
      const response = await subscriptionInstance.post("/cancel");

      console.log(response.data);

      setCancelLoading(false);
      handleClose();

      if (response.data?.status === "no-subscription") {
        toast.error("You don't have any subscription to cancel", {
          position: "bottom-center",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
        });
      } else {
        dispatch(cancelSubscription());
        navigate("/dashboard/subscription");
      }
    } catch (error) {
      console.log(error);
      setCancelLoading(false);
      handleClose();
    }
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Grid container spacing={3}>
      <Grid
        item
        xs={12}
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
      >
        <Typography variant="h4">Billing</Typography>
        <Button onClick={handleClickOpen}>Cancel subscription</Button>
      </Grid>
      <Grid item xs={12}>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell align="left">Email</TableCell>
                <TableCell align="left">Amount</TableCell>
                <TableCell align="left">Time</TableCell>
                <TableCell align="left">Status</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {isLoading ? (
                <TableRow
                  key={1}
                  sx={{
                    "&:last-child td, &:last-child th": { border: 0 },
                  }}
                >
                  <TableCell
                    component="th"
                    scope="row"
                    colSpan={4}
                    align="center"
                  >
                    <CircularProgress color="primary" size={50} />
                  </TableCell>
                </TableRow>
              ) : transactions?.length > 0 ? (
                transactions?.map((transaction) => (
                  <TableRow
                    key={transaction?.id}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    align="left"
                  >
                    <TableCell component="th" scope="row" align="left">
                      {transaction?.payer_email}
                    </TableCell>
                    <TableCell align="left">
                      {transaction?.amount_with_breakdown.gross_amount.value}{" "}
                      {
                        transaction?.amount_with_breakdown.gross_amount
                          .currency_code
                      }
                    </TableCell>
                    <TableCell align="left">
                      {moment(transaction?.time).format("MMMM Do YYYY")}
                    </TableCell>
                    <TableCell align="left">{transaction?.status}</TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow
                  key={1}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell
                    component="th"
                    scope="row"
                    colSpan={4}
                    align="center"
                  >
                    No Transactions
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
      <Dialog
        open={open}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Cancel Subscription</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to cancel your subscription. You will not be
            able to use the application anymore.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} autoFocus disabled={cancelLoading}>
            Cancel
          </Button>
          <Button onClick={handleCancelSubscription}>
            {cancelLoading ? (
              <CircularProgress color="primary" size={20} />
            ) : (
              "Continue"
            )}
          </Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );
};

export default BillingSection;
