import { Box, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import Clock from "react-clock";
import "react-clock/dist/Clock.css";
import { useMediaQuery } from "react-responsive";

const AnalogClock = ({
  city,
  timeZone,
  isMobile,
  isTablet,
  isDesktop,
  isLargeDesktop,
}) => {
  const [value, setValue] = useState(
    new Date(new Date().toLocaleString("en-US", { timeZone: timeZone }))
  );

  useEffect(() => {
    const interval = setInterval(
      () =>
        setValue(
          new Date(
            new Date().toLocaleString("en-US", {
              timeZone: timeZone,
            })
          )
        ),
      1000
    );

    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <Box>
      <Clock
        value={value}
        size={
          isMobile
            ? 100
            : isTablet
            ? 140
            : isDesktop
            ? 120
            : isLargeDesktop
            ? 200
            : 300
        }
        locale="en-US"
      />
      <Typography variant="h6" sx={{ mt: 1 }} textAlign="center">
        {city}
      </Typography>
    </Box>
  );
};

export default AnalogClock;
