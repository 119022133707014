import { Box } from "@mui/material";
import StoreProductsSection from "src/sections/@dashboard/products/byStore";

export default function StoreProducts() {
  return (
    <Box sx={{ padding: "0rem 2rem" }}>
      <StoreProductsSection />
    </Box>
  );
}
