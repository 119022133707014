import { useState, useEffect, useReducer, useCallback, useRef } from "react";
import productInstance from "src/axios/productInstance";
import {
  Box,
  Grid,
  TextField,
  IconButton,
  MenuItem,
  Menu,
  Typography,
  InputAdornment,
  Pagination,
  Stack,
  FormControl,
  Select,
  InputLabel,
  Container,
} from "@mui/material";
import ProductsCardsView from "src/sections/@dashboard/products/productsCardView";
import FilterListIcon from "@mui/icons-material/FilterList";
import _ from "lodash";
import SearchIcon from "@mui/icons-material/Search";
import CircularProgress from "@mui/material/CircularProgress";
import { useNavigate } from "react-router-dom";

const initialState = {
  loading: true,
  products: [],
  savedProducts: [],
  categories: [],
  marketplaceProductCount: 0,
  totalPages: 0,
  currentPage: 1,
  errorMessage: "",
  sortBy: "",
  sortDirection: "",
  category: "",
  location: "",
  minFeedback: "",
  maxFeedback: "",
  searchKeyword: "",
};

const reducer = (state, action) => {
  switch (action.type) {
    case "start-loading":
      return { ...state, loading: true };
    case "stop-loading":
      return { ...state, loading: false };
    case "products":
      return { ...state, products: action.payload };
    case "savedProducts":
      return { ...state, savedProducts: action.payload };
    case "categories":
      return { ...state, categories: action.payload };
    case "marketplaceProductCount":
      return { ...state, marketplaceProductCount: action.payload };
    case "totalPages":
      return { ...state, totalPages: action.payload };
    case "currentPage":
      return { ...state, currentPage: action.payload };
    case "errorMessage":
      return { ...state, errorMessage: action.payload };
    case "sortBy":
      return { ...state, currentPage: 1, sortBy: action.payload };
    case "sortDirection":
      return { ...state, currentPage: 1, sortDirection: action.payload };
    case "category":
      return { ...state, currentPage: 1, category: action.payload };
    case "location":
      return { ...state, currentPage: 1, location: action.payload };
    case "minFeedback":
      return { ...state, currentPage: 1, minFeedback: action.payload };
    case "maxFeedback":
      return { ...state, currentPage: 1, maxFeedback: action.payload };
    case "searchKeyword":
      return { ...state, currentPage: 1, searchKeyword: action.payload };
    default:
      return state;
  }
};

const Products = () => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [inputMin, setInputMin] = useState("");
  const [inputMax, setInputMax] = useState("");
  const [searchkeyword, setSearchKeyword] = useState("");
  const navigate = useNavigate();

  const initialLoadRef = useRef(true);

  const productsPerPage = 80;

  const paginate = (pageNumber) => {
    dispatch({ type: "currentPage", payload: pageNumber });
  };

  useEffect(() => {
    fetchSavedProducts();
    fetchCategories();
  }, []);

  const fetchProducts = useCallback(async (queryString) => {
    dispatch({ type: "start-loading" });
    try {
      const response = await productInstance.get(`?${queryString}`);
      const { totalProducts, totalPages, products } = response.data;
      dispatch({ type: "products", payload: products });
      dispatch({ type: "totalPages", payload: totalPages });
      dispatch({ type: "marketplaceProductCount", payload: totalProducts });
      dispatch({ type: "errorMessage", payload: "" });
    } catch (error) {
      dispatch({
        type: "errorMessage",
        payload: error.response.data.message,
      });
    } finally {
      dispatch({ type: "stop-loading" });
    }
  }, []);

  useEffect(() => {
    if (initialLoadRef.current) {
      const urlParams = new URLSearchParams(window.location.search);

      urlParams.set("pageSize", productsPerPage);

      const currentPage = urlParams.get("page") || 1;

      const category = urlParams.get("category") || "";
      const location = urlParams.get("location") || "";
      const minFeedback = urlParams.get("minFeedback") || "";
      const maxFeedback = urlParams.get("maxFeedback") || "";
      const sortBy = urlParams.get("sortBy") || "";
      const sortDirection = urlParams.get("sortDirection") || "";
      const searchKeyword = urlParams.get("searchKeyword") || "";

      // Dispatch actions to update reducer state
      dispatch({ type: "currentPage", payload: currentPage });
      dispatch({ type: "category", payload: category });
      dispatch({ type: "location", payload: location });
      dispatch({ type: "minFeedback", payload: minFeedback });
      dispatch({ type: "maxFeedback", payload: maxFeedback });
      dispatch({ type: "sortBy", payload: sortBy });
      dispatch({ type: "sortDirection", payload: sortDirection });
      dispatch({ type: "searchKeyword", payload: searchKeyword });

      setInputMin(minFeedback);
      setInputMax(maxFeedback);
      setSearchKeyword(searchKeyword);

      initialLoadRef.current = false;

      const queryString = urlParams.toString();

      console.log({ urlString: queryString });
      window.history.replaceState({}, "", `?${queryString}`);

      fetchProducts(queryString);
    } else {
      const searchParams = new URLSearchParams();

      searchParams.set("page", state.currentPage);
      searchParams.set("pageSize", productsPerPage);

      if (state.category !== "") {
        searchParams.set("category", state.category);
      } else {
        searchParams.delete("category");
      }

      if (state.location !== "") {
        searchParams.set("location", state.location);
      } else {
        searchParams.delete("location");
      }

      if (state.minFeedback !== "") {
        searchParams.set("minFeedback", state.minFeedback);
      } else {
        searchParams.delete("minFeedback");
      }

      if (state.maxFeedback !== "") {
        searchParams.set("maxFeedback", state.maxFeedback);
      } else {
        searchParams.delete("maxFeedback");
      }

      if (state.sortBy !== "") {
        searchParams.set("sortBy", state.sortBy);
      } else {
        searchParams.delete("sortBy");
      }

      if (state.sortDirection !== "") {
        searchParams.set("sortDirection", state.sortDirection);
      } else {
        searchParams.delete("sortDirection");
      }

      if (state.searchKeyword !== "") {
        searchParams.set("searchKeyword", state.searchKeyword);
      } else {
        searchParams.delete("searchKeyword");
      }

      const queryString = searchParams.toString();

      console.log({ queryString });
      window.history.replaceState({}, "", `?${queryString}`);

      fetchProducts(queryString);
    }
  }, [
    fetchProducts,
    state.currentPage,
    state.category,
    state.location,
    state.minFeedback,
    state.maxFeedback,
    state.sortBy,
    state.sortDirection,
    state.searchKeyword,
    navigate,
  ]);

  const fetchCategories = async () => {
    const response = await productInstance.get("/categories");
    dispatch({ type: "categories", payload: response.data.categories });
  };

  const fetchSavedProducts = async () => {
    const response = await productInstance.get("/saved");
    dispatch({ type: "saved-products", payload: response.data.products });
  };

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    event.stopPropagation();
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const [anchorEl2, setAnchorEl2] = useState(null);
  const open2 = Boolean(anchorEl2);

  const handleClick2 = (event) => {
    setAnchorEl2(event.currentTarget);
    event.stopPropagation();
  };
  const handleClose2 = () => {
    setAnchorEl2(null);
  };

  const debouncedInputChange = useCallback(
    _.debounce((key, value) => {
      dispatch({ type: key, payload: value });
    }, 1000),
    []
  );

  const handleSearchKeywordChange = (event) => {
    const inputValue = event.target.value;
    setSearchKeyword(inputValue);

    debouncedInputChange("searchKeyword", inputValue);
  };

  const handleMinFeedbackInputChange = (event) => {
    const inputValue = event.target.value;

    setInputMin(inputValue);

    if (Number(inputValue) < 0) {
      dispatch({
        type: "errorMessage",
        payload: "Please enter a positive number",
      });
      return;
    }

    dispatch({
      type: "errorMessage",
      payload: "",
    });

    debouncedInputChange("minFeedback", inputValue);
  };

  const handleMaxFeedbackInputChange = (event) => {
    const inputValue = event.target.value;
    setInputMax(inputValue);
    if (inputValue !== "" && Number(inputValue) < Number(inputMin)) {
      dispatch({
        type: "errorMessage",
        payload: "Please enter a number greater than min feedback",
      });
      return;
    }

    dispatch({
      type: "errorMessage",
      payload: "",
    });
    debouncedInputChange("maxFeedback", inputValue);
  };

  return (
    <Box
      sx={{
        padding: "0rem 2rem",
      }}
    >
      <Grid container spacing={6}>
        <Grid item md={12} sm={12} xs={12}>
          <Grid container spacing={2}>
            <Grid item md={12}>
              <Typography variant="body1">
                {state.marketplaceProductCount} Products
              </Typography>
            </Grid>
            <Grid item md={12} sm={12} xs={12}>
              <Grid
                container
                spacing={2}
                justifyContent="flex-start"
                alignItems="center"
              >
                <Grid item md={2} sm={4} xs={6}>
                  <FormControl sx={{ minWidth: "100%" }} size="small">
                    <InputLabel id="demo-select-small">Sort by date</InputLabel>
                    <Select
                      labelId="demo-select-small"
                      id="demo-select-small"
                      value={state.sortBy === "date" ? state.sortDirection : ""}
                      label="Sort by date"
                      onChange={(e) => {
                        dispatch({
                          type: "sortBy",
                          payload: "date",
                        });
                        dispatch({
                          type: "sortDirection",
                          payload: e.target.value,
                        });
                      }}
                      autoWidth
                    >
                      <MenuItem value="desc">Newest to oldest</MenuItem>
                      <MenuItem value="asc">Oldest to newest</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item md={2} sm={4} xs={6}>
                  <FormControl sx={{ minWidth: "100%" }} size="small">
                    <InputLabel id="demo-select-small">
                      Sort by price
                    </InputLabel>
                    <Select
                      labelId="demo-select-small"
                      id="demo-select-small"
                      value={
                        state.sortBy === "price" ? state.sortDirection : ""
                      }
                      label="Sort by price"
                      onChange={(e) => {
                        dispatch({
                          type: "sortBy",
                          payload: "price",
                        });
                        dispatch({
                          type: "sortDirection",
                          payload: e.target.value,
                        });
                      }}
                    >
                      <MenuItem value="desc">Highest to lowest</MenuItem>
                      <MenuItem value="asc">Lowest to highest</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item md={2} sm={4} xs={6}>
                  <FormControl sx={{ minWidth: "100%" }} size="small">
                    <InputLabel id="demo-select-small">
                      Sort by popularity
                    </InputLabel>
                    <Select
                      labelId="demo-select-small"
                      id="demo-select-small"
                      value={
                        state.sortBy === "popularity" ? state.sortDirection : ""
                      }
                      label="Sort by popularity"
                      onChange={(e) => {
                        dispatch({
                          type: "sortBy",
                          payload: "popularity",
                        });
                        dispatch({
                          type: "sortDirection",
                          payload: e.target.value,
                        });
                      }}
                    >
                      <MenuItem value="desc">Most popular</MenuItem>
                      <MenuItem value="asc">Least popular</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item md={6} sm={4} xs={6}>
                  <TextField
                    fullWidth
                    size="small"
                    id="outlined-basic"
                    placeholder="Search Products"
                    value={searchkeyword}
                    variant="outlined"
                    onChange={handleSearchKeywordChange}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item md={2} sm={4} xs={6}>
                  <Box fullWidth>
                    <IconButton
                      aria-label="more"
                      id="long-button"
                      aria-controls={open ? "long-menu" : undefined}
                      aria-expanded={open ? "true" : undefined}
                      aria-haspopup="true"
                      onClick={handleClick}
                      size="small"
                      fullWidth
                      sx={{
                        borderRadius: "10px",
                        border: "1px solid #D7DDE1",
                        display: "flex",
                        gap: "0.5rem",
                        padding: "8px 14px",
                        justifyContent: "left",
                        alignItems: "center",
                        width: "100%",
                      }}
                    >
                      <FilterListIcon />
                      <Typography variant="body2">Category</Typography>
                    </IconButton>
                    <Menu
                      id="options-menu"
                      anchorEl={anchorEl}
                      open={open}
                      onClose={handleClose}
                    >
                      {state.categories?.map((category) => (
                        <MenuItem
                          key={category._id}
                          onClick={(event) => {
                            handleClose();

                            event.stopPropagation();

                            dispatch({
                              type: "category",
                              payload: category._id,
                            });
                          }}
                          selected={state.category === category._id}
                        >
                          <Stack
                            direction="row"
                            justifyContent="space-between"
                            width="100%"
                          >
                            <Typography variant="body2">
                              {category._id}
                            </Typography>

                            <Typography variant="body2">
                              {category.count}
                            </Typography>
                          </Stack>
                        </MenuItem>
                      ))}
                    </Menu>
                  </Box>
                </Grid>
                <Grid item md={2} sm={4} xs={6}>
                  <Box fullWidth>
                    <IconButton
                      aria-label="more"
                      id="long-button"
                      aria-controls={open2 ? "long-menu" : undefined}
                      aria-expanded={open2 ? "true" : undefined}
                      aria-haspopup="true"
                      onClick={handleClick2}
                      size="small"
                      fullWidth
                      sx={{
                        borderRadius: "10px",
                        width: "100%",
                        border: "1px solid #D7DDE1",
                        display: "flex",
                        padding: "8px 14px",
                        gap: "0.5rem",
                        justifyContent: "start",
                        alignItems: "center",
                      }}
                    >
                      <FilterListIcon />
                      <Typography variant="body2">Location</Typography>
                    </IconButton>
                    <Menu
                      id="options-menu"
                      anchorEl={anchorEl2}
                      open={open2}
                      onClose={handleClose2}
                    >
                      {["All", "China", "USA"]?.map((location) => (
                        <MenuItem
                          key={location}
                          onClick={(event) => {
                            handleClose2();

                            event.stopPropagation();

                            dispatch({
                              type: "location",
                              payload: location,
                            });
                          }}
                          selected={state.location === location}
                        >
                          <Stack
                            direction="row"
                            justifyContent="space-between"
                            width="100%"
                          >
                            <Typography variant="body2">{location}</Typography>
                          </Stack>
                        </MenuItem>
                      ))}
                    </Menu>
                  </Box>
                </Grid>
                <Grid item md={2} sm={4} xs={6}>
                  <TextField
                    fullWidth
                    size="small"
                    id="outlined-basic"
                    placeholder="Min Feedback"
                    value={inputMin}
                    variant="outlined"
                    onChange={handleMinFeedbackInputChange}
                  />
                </Grid>
                <Grid item md={2} sm={4} xs={6}>
                  <TextField
                    fullWidth
                    size="small"
                    id="outlined-basic"
                    placeholder="Max Feedback"
                    value={inputMax}
                    variant="outlined"
                    onChange={handleMaxFeedbackInputChange}
                  />
                </Grid>

                {state.errorMessage && (
                  <Grid item md={12} sx={{ color: "red", marginTop: 1 }}>
                    {state.errorMessage}
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        {state.loading ? (
          <Container
            sx={{
              height: "50vh",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CircularProgress />
          </Container>
        ) : (
          <>
            <Grid item md={12} sm={12} xs={12}>
              <ProductsCardsView
                products={state.products}
                savedProducts={state.savedProducts}
                isSavedSection={false}
                fetchProducts={fetchProducts}
                fetchSavedProducts={fetchSavedProducts}
              />
            </Grid>
            {state.products.length > 0 && (
              <Grid
                item
                md={12}
                sm={12}
                xs={12}
                mt={10}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Pagination
                  count={state.totalPages}
                  page={state.currentPage}
                  onChange={(event, value) => paginate(value)}
                  variant="outlined"
                  shape="rounded"
                  size="large"
                />
              </Grid>
            )}
          </>
        )}
      </Grid>
    </Box>
  );
};

export default Products;
