import {
  Card,
  CardContent,
  CardHeader,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import moment from "moment";

export default function PostsCard({
  posts,
  isMobile,
  isTablet,
  isDesktop,
  isLargeDesktop,
}) {
  return (
    <Card
      sx={{
        height: "100%",
        borderRadius: "1rem",
        boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.25)",
      }}
    >
      <CardContent
        sx={{
          padding: isMobile ? "1rem" : isTablet ? "1.5rem" : "1rem",
        }}
      >
        <Typography
          variant="h6"
          sx={{ color: "primary.main", marginBottom: "1rem" }}
        >
          Latest News & Updates
        </Typography>
        <Grid container spacing={2}>
          {posts.map((post) => (
            <Grid item md={12} sm={12} xs={12}>
              <Stack direction="row" spacing={1}>
                <Stack direction="column" alignItems="flex-start" spacing={0}>
                  <Typography
                    variant="body1"
                    textAlign="left"
                    sx={{
                      fontSize: isMobile ? "0.75rem" : "1rem",
                      fontWeight: "bold",
                      color: "primary.main",
                    }}
                  >
                    {moment(post.updatedAt).format("Do")}
                  </Typography>
                  <Typography
                    variant="body1"
                    sx={{
                      fontSize: isMobile ? "0.75rem" : "1rem",
                      fontWeight: "bold",
                      color: "primary.main",
                    }}
                  >
                    {moment(post.updatedAt).format("MMM")}
                  </Typography>
                </Stack>
                <Typography
                  variant="body1"
                  sx={{
                    fontSize: "1rem",
                    fontWeight: "600",
                    display: "-webkit-box",
                    WebkitLineClamp: "3",
                    WebkitBoxOrient: "vertical",
                    overflow: "hidden",
                  }}
                >
                  {post.title}
                </Typography>
              </Stack>
            </Grid>
          ))}
        </Grid>
      </CardContent>
    </Card>
  );
}
