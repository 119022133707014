import { useState, useEffect } from "react";
import {
  Grid,
  Typography,
  AppBar,
  Tabs,
  Tab,
  Box,
  Container,
} from "@mui/material";
import AccountForm from "./accountForm";
import BillingSection from "./billingSection";
import { useSearchParams } from "react-router-dom";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const AccountSection = () => {
  const [searchParams] = useSearchParams();

  const [value, setValue] = useState(() => {
    if (searchParams.get("tab") === "billing") {
      return 1;
    }
    return 0;
  });

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Container>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
        >
          <Tab label="Account" {...a11yProps(0)} />
          <Tab label="Billing" {...a11yProps(1)} />
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        <AccountForm />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <BillingSection />
      </TabPanel>
    </Container>
  );
};

export default AccountSection;
