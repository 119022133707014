import { useState, useEffect } from "react";
import productInstance from "src/axios/productInstance";
import {
  Box,
  Grid,
  TextField,
  IconButton,
  MenuItem,
  Menu,
  Typography,
  Backdrop,
  InputAdornment,
  Pagination,
  Stack,
  FormControl,
  Select,
  InputLabel,
  Container,
} from "@mui/material";
import ProductsCardsView from "../productsCardView";
import _ from "lodash";
import SearchIcon from "@mui/icons-material/Search";
import CircularProgress from "@mui/material/CircularProgress";
import { useParams } from "react-router-dom";

const CategoryProductsSection = ({ isSavedSection }) => {
  const { categoryId } = useParams();

  const [products, setProducts] = useState();
  const [savedProducts, setSavedProducts] = useState([]);
  const [loading, setLoading] = useState(true);

  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  const [sortBy, setSortBy] = useState({
    date: "",
    price: "",
    quantitySold: "",
  });

  const productsPerPage = 80;

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  useEffect(() => {
    fetchProducts();
    fetchSavedProducts();
  }, []);

  const fetchProducts = async () => {
    const response = await productInstance.get(`category/${categoryId}`);
    setProducts(response.data.products);
    setTotalPages(response.data.totalPages);

    if (response.data.products) {
      setLoading(false);
    }
  };

  const fetchSavedProducts = async () => {
    const response = await productInstance.get("/saved");
    setSavedProducts(response.data.products);
  };

  const handleProductSearch = _.debounce(async (value) => {
    const response = await productInstance.get(
      `/search?keyword=${value}&page=${currentPage}&pageSize=${productsPerPage}&category=${categoryId}`
    );
    setProducts(response.data.products);
    setTotalPages(response.data.totalPages);
  }, 500);

  const handleSortByChange = (key, value) => {
    setSortBy({ ...sortBy, [key]: value });

    let sortedProducts = [];
    if (key === "date") {
      sortedProducts = _.orderBy(products, ["createdAt"], [value]);
      setProducts(sortedProducts);
    }

    if (key === "price") {
      sortedProducts = _.orderBy(products, ["priceInUSD"], [value]);
    }

    if (key === "quantitySold") {
      sortedProducts = _.orderBy(products, ["totalItemsSold"], [value]);
    }

    if (key === "date") {
      sortedProducts = _.orderBy(products, ["soldDate"], [value]);
    }

    setProducts(sortedProducts);
  };

  return (
    <Grid container spacing={6}>
      {loading ? (
        <Container
          sx={{
            height: "50vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress />
        </Container>
      ) : (
        <>
          {" "}
          <Grid item md={12} sm={12} xs={12}>
            <Grid container spacing={2}>
              <Grid item md={12} sm={12} xs={12}>
                <Grid
                  container
                  spacing={2}
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Grid item md={2} sm={4} xs={6}>
                    <FormControl sx={{ m: 1, minWidth: "100%" }} size="small">
                      <InputLabel id="demo-select-small">
                        Sort by date
                      </InputLabel>
                      <Select
                        labelId="demo-select-small"
                        id="demo-select-small"
                        value={sortBy.date}
                        label="Sort by date"
                        onChange={(e) => {
                          handleSortByChange("date", e.target.value);
                        }}
                        autoWidth
                      >
                        <MenuItem value="desc">Newest to oldest</MenuItem>
                        <MenuItem value="asc">Oldest to newest</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item md={2} sm={4} xs={6}>
                    <FormControl sx={{ m: 1, minWidth: "100%" }} size="small">
                      <InputLabel id="demo-select-small">
                        Sort by price
                      </InputLabel>
                      <Select
                        labelId="demo-select-small"
                        id="demo-select-small"
                        value={sortBy.price}
                        label="Sort by price"
                        onChange={(e) => {
                          handleSortByChange("price", e.target.value);
                        }}
                      >
                        <MenuItem value="desc">Highest to lowest</MenuItem>
                        <MenuItem value="asc">Lowest to highest</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item md={2} sm={4} xs={6}>
                    <FormControl sx={{ m: 1, minWidth: "100%" }} size="small">
                      <InputLabel id="demo-select-small">
                        Sort by popularity
                      </InputLabel>
                      <Select
                        labelId="demo-select-small"
                        id="demo-select-small"
                        value={sortBy.quantitySold}
                        label="Sort by popularity"
                        onChange={(e) => {
                          handleSortByChange("quantitySold", e.target.value);
                        }}
                      >
                        <MenuItem value="desc">Most popular</MenuItem>
                        <MenuItem value="asc">Least popular</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item md={4} sm={6}>
                    <TextField
                      fullWidth
                      size="small"
                      id="outlined-basic"
                      placeholder="Search Products"
                      variant="outlined"
                      onChange={(event) => {
                        handleProductSearch(event.target.value);
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <SearchIcon />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item md={12} sm={12} xs={12}>
            <ProductsCardsView
              products={products}
              savedProducts={savedProducts}
              isSavedSection={false}
              fetchProducts={fetchProducts}
              fetchSavedProducts={fetchSavedProducts}
              loading={loading}
            />
          </Grid>
        </>
      )}

      {products?.length > 0 && (
        <Grid
          item
          md={12}
          sm={12}
          xs={12}
          mt={10}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Pagination
            count={totalPages}
            page={currentPage}
            onChange={(event, value) => paginate(value)}
            variant="outlined"
            shape="rounded"
            size="large"
          />
        </Grid>
      )}
    </Grid>
  );
};

export default CategoryProductsSection;
