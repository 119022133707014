import PayPalBtn from "../../components/PayPalButton";
import subscriptionInstance from "src/axios/subscriptionInstance";
import { Grid, Card, CardContent, Button, Stack, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { createSubscription } from "src/store/actions/authActions";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

function PayPalSubscription({ toCoupon }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { userID } = useSelector((state) => state.auth);

  const paypalSubscribe = (data, actions) => {
    return actions.subscription.create({
      plan_id: process.env.REACT_APP_PAYPAL_SUBSCRIPTION_PLAN_ID,
      // plan_id: "P-64394168LL933240TMOTADHQ",
      user_id: userID,
    });
  };

  const paypalOnError = (err) => {
    console.log("Error");
  };

  const paypalOnApprove = async (data, detail) => {
    try {
      console.log({ data, detail });

      await subscriptionInstance.post("/", data);
      dispatch(createSubscription());
      const toastId = toast.loading("Loading...");

      toast.update(toastId, {
        render: "Subscription created successfully",
        type: "success",
        isLoading: false,
        autoClose: 1000,
      });

      navigate("/dashboard/main");
    } catch (error) {
      console.log("error: ", error);
    }
  };

  const cancelSubscription = async () => {
    // await subscriptionInstance.post(`/cancel`);
    // console.log("Subscription cancelled");
  };

  return (
    <Grid
      container
      sx={{
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Grid item xs={12} md={6}>
        <Grid container spacing={5}>
          <Grid item xs={12}>
            <Typography
              variant="h3"
              sx={{
                textAlign: "center",
                fontWeight: "bold",
                color: "primary.main",
              }}
            >
              Please subscribe to continue
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Card
              sx={{
                padding: "1rem",
              }}
            >
              <CardContent>
                <Stack spacing={2}>
                  <Button
                    onClick={toCoupon}
                    variant="outlined"
                    color="primary"
                    size="lg"
                    sx={{
                      width: "100%",
                      padding: "10px",
                      borderRadius: "30px",
                    }}
                  >
                    Use Coupon
                  </Button>
                  <PayPalBtn createSubscription={paypalSubscribe} onApprove={paypalOnApprove} catchError={paypalOnError} onError={paypalOnError} onCancel={paypalOnError} />
                </Stack>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default PayPalSubscription;
