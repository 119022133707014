import { Box } from "@mui/material";
import CategoryProductsSection from "src/sections/@dashboard/products/byCategory";

export default function CategoryProducts() {
  return (
    <Box
      sx={{
        padding: "0rem 2rem",
      }}
    >
      <CategoryProductsSection />
    </Box>
  );
}
