import dashboardInstance from "../axios/dashboardInstance";
import {
  Grid,
  Container,
  Stack,
  CircularProgress,
  Card,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";

// components
import Page from "../components/Page";
// sections
import Clock from "src/components/analogClock";
import BarChart from "src/components/charts/barChart";
import GoogleTrends from "src/components/googleTrends";
import PostsCard from "src/components/cards/postsCard";
import GettingStartedCard from "src/components/cards/gettingStartedCard";
import ConnectCard from "src/components/cards/connectCard";
import HolidaysCard from "src/components/cards/holidaysCard";
import { useMediaQuery } from "react-responsive";

import Loader from "src/components/Loader";
import useSWR, { mutate } from "swr";

// ----------------------------------------------------------------------
const LoaderWrapper = styled("div")(({ theme }) => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  zIndex: "1000",
}));

export default function DashboardApp() {
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 });
  const isDesktop = useMediaQuery({ minWidth: 992 });
  const isLargeDesktop = useMediaQuery({ query: "(min-width: 1200px)" });

  // useEffect(() => {
  //   fetchDashboardStats();
  // }, []);

  // const fetchDashboardStats = async () => {
  //   setLoading(true);
  //   try {
  //     const response = await dashboardInstance.get("/");
  //     setDashboardStats({
  //       products: response.data.products,
  //       trends: response.data.trends,
  //       posts: response.data.posts,
  //     });
  //     setLoading(false);
  //   } catch (error) {
  //     console.log(error);
  //   }

  //   setLoading(false);
  // };

  const fetchDashboardStats = async () => {
    const response = await dashboardInstance.get("/");

    return {
      products: response.data.products,
      trends: response.data.trends,
      posts: response.data.posts,
    };
  };

  const { data, error, isLoading } = useSWR("/dashboard", fetchDashboardStats);

  if (error) return <div>failed to load</div>;

  if (isLoading) return <Loader />;

  const { products, trends, posts } = data;

  return (
    <Page title="Dashboard">
      <Container maxWidth="xl">
        <Grid container spacing={3}>
          <Grid item xs={12} md={12} lg={12} mb={2}>
            <Typography variant="h4" sx={{ color: "primary.main" }}>
              Welcome again !
            </Typography>
          </Grid>
          <Grid item xs={12} md={12} lg={12}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={3} lg={3}>
                <PostsCard
                  posts={posts}
                  isMobile={isMobile}
                  isTablet={isTablet}
                  isDesktop={isDesktop}
                  isLargeDesktop={isLargeDesktop}
                />
              </Grid>
              <Grid item xs={12} md={6} lg={6}>
                <Card
                  sx={{
                    height: "100%",
                    borderRadius: "1rem",
                    padding: "1rem",
                    boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.25)",
                  }}
                >
                  <Stack spacing={3}>
                    <Stack
                      spacing={3}
                      direction="row"
                      justifyContent="space-around"
                    >
                      <Clock
                        timeZone="Asia/Jerusalem"
                        city="Tel Aviv"
                        isMobile={isMobile}
                        isTablet={isTablet}
                        isDesktop={isDesktop}
                        isLargeDesktop={isLargeDesktop}
                      />
                      <Clock
                        timeZone="Europe/London"
                        city="London"
                        isMobile={isMobile}
                        isTablet={isTablet}
                        isDesktop={isDesktop}
                        isLargeDesktop={isLargeDesktop}
                      />
                      <Clock
                        timeZone="America/New_York"
                        city="New York"
                        isMobile={isMobile}
                        isTablet={isTablet}
                        isDesktop={isDesktop}
                        isLargeDesktop={isLargeDesktop}
                      />
                    </Stack>
                    <BarChart products={products} />
                  </Stack>
                </Card>
              </Grid>
              <Grid item xs={12} md={3} lg={3}>
                <GoogleTrends
                  trends={trends}
                  isMobile={isMobile}
                  isTablet={isTablet}
                  isDesktop={isDesktop}
                  isLargeDesktop={isLargeDesktop}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={12} lg={12}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={3} lg={3}>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={12} lg={12}>
                    <GettingStartedCard />
                  </Grid>
                  <Grid item xs={12} md={12} lg={12}>
                    <ConnectCard />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} md={6} lg={6}>
                <HolidaysCard />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
}
