// component
import Iconify from "../../components/Iconify";

// ----------------------------------------------------------------------

const getIcon = (name) => <Iconify icon={name} width={22} height={22} />;

const navConfig = [
  {
    title: "dashboard",
    path: "/dashboard/main",
    icon: getIcon("eva:pie-chart-2-fill"),
  },
  {
    title: "products",
    path: "/dashboard/products",
    icon: getIcon("eva:people-fill"),
  },
  {
    title: "saved products",
    path: "/dashboard/saved-products",
    icon: getIcon("ant-design:ordered-list-outlined"),
  },
  {
    title: "account",
    path: "/dashboard/account",
    icon: getIcon("eva:book-open-outline"),
  },
];

export default navConfig;
