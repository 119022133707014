import React, { useState, useEffect } from "react";
import {
  Card,
  CardContent,
  CardHeader,
  Grid,
  Stack,
  Typography,
} from "@mui/material";

import axios from "axios";
import moment from "moment";

export default function HolidaysCard() {
  const [holidays, setHolidays] = useState({
    china: [],
    us: [],
  });

  useEffect(() => {
    fetcHolidays();
  }, []);

  const fetcHolidays = async () => {
    const year = new Date().getFullYear();
    const usUrl = `https://date.nager.at/api/v3/publicholidays/${year}/US`;
    const chinaUrl = `https://date.nager.at/api/v3/publicholidays/${year}/CN`;

    const usResponse = await axios.get(usUrl);
    const chinaResponse = await axios.get(chinaUrl);

    Promise.all([usResponse, chinaResponse]).then((values) => {
      console.log("values: ", values);
      setHolidays({
        us: values[0].data,
        china: values[1].data,
      });
    });
  };

  return (
    <Card
      sx={{
        height: "100%",
        borderRadius: "1rem",
        boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.25)",
      }}
    >
      <CardContent>
        <Grid container spacing={4}>
          <Grid item xs={12} md={6}>
            <Typography
              variant="h6"
              sx={{ mt: 1, mb: 1, color: "primary.main" }}
              textAlign="center"
            >
              US Holidays
            </Typography>
            <Grid container spacing={1}>
              {holidays?.us?.map((holiday) => (
                <Grid item xs={12} md={12}>
                  <Grid container spacing={1}>
                    <Grid item md={8}>
                      <Typography variant="body2" textAlign="left">
                        {holiday.name}
                      </Typography>
                    </Grid>
                    <Grid item md={4}>
                      <Typography
                        variant="caption"
                        display="flex"
                        justifyContent="flex-end"
                      >
                        {moment(holiday.date).format("D MMM, YYYY")}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              ))}
            </Grid>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography
              variant="h6"
              sx={{ mt: 1, mb: 1, color: "primary.main" }}
              textAlign="center"
            >
              China Holidays
            </Typography>
            <Grid container spacing={2}>
              {holidays?.china?.map((holiday) => (
                <Grid item xs={12} md={12}>
                  <Grid
                    container
                    spacing={1}
                    justifyContent="center"
                    alignItems={"center"}
                  >
                    <Grid item md={8}>
                      <Typography variant="body2" textAlign="left">
                        {holiday.name}
                      </Typography>
                    </Grid>
                    <Grid item md={4}>
                      <Typography
                        variant="caption"
                        display="flex"
                        justifyContent="flex-end"
                      >
                        {moment(holiday.date).format("D MMM, YYYY")}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}
